<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 11C14 8.7505 13.2496 8 11.0005 8C8.75036 8 8 8.7505 8 11C8 13.2505 8.75036 14 11.0005 14C13.2496 14 14 13.2505 14 11Z"
      stroke="#1A222C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18 11H24"
      stroke="#1A222C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14 21L8 21"
      stroke="#1A222C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 21C18 23.2505 18.7505 24 20.9999 24C23.2495 24 24 23.2505 24 21C24 18.7495 23.2495 18 20.9999 18C18.7505 18 18 18.7495 18 21Z"
      stroke="#1A222C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>