import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    cards: [],
    basket: [],
    shoes: [],
  },
  getters: {
    cards(state) {
      return state.cards;
    },
    shoes(state) {
      return state.shoes;
    },
    basket(state) {
      return state.basket;
    },
  },
  mutations: {
    updateCards(state, cards) {
      return (state.cards = cards);
    },
    updateShoes(state, shoes) {
      return (state.shoes = shoes);
    },
    updateBasket(state, card) {
      if (state.basket.includes(card)) {
        card.count++;
      } else {
        state.basket.push(card);
      }

      return state;
    },
    deleteItemBasket(state, card) {
      if (state.basket.includes(card)) {
        state.basket.forEach((el) => {
          if (el.key == card.key) {
            if (el.count > 1) {
              el.count--;
            } else {
              state.basket = state.basket.filter((x) => {
                return x.key != card.key;
              });
            }
          }
        });
      }
    },
  },
  actions: {
    async getCards(ctx, commit) {
      let rows = await axios
        .get(
          "https://stepbystep-b7ccc-default-rtdb.europe-west1.firebasedatabase.app/products.json?print=pretty"
        )
        .then((response) => {
          let items = response.data;

          for (let item in items) {
            items[item].key = item;
            let newpath = `https://firebasestorage.googleapis.com/v0/b/stepbystep-b7ccc.appspot.com/o/images%2F${items[item].imagesrc}?alt=media&token=9b5726b6-ff94-44d4-aafc-0589af151732`;
            items[item].imagesrc = newpath;
            items[item].count = 1;
          }
          return items;
        });
      ctx.commit("updateCards", rows);
    },
    async getShoes(ctx, commit) {
      let rows = await axios
        .get(
          "https://stepbystep-b7ccc-default-rtdb.europe-west1.firebasedatabase.app/clothes.json?print=pretty"
        )
        .then((response) => {
          let items = response.data;
          for (let item in items) {
            items[item].key = item;
            console.log(items[item].brand);
            items[
              item
            ].imagesrc1 = `https://firebasestorage.googleapis.com/v0/b/stepbystep-b7ccc.appspot.com/o/shoes%2F${items[item].name}%2F${items[item].imagesrc1}?alt=media&token=ab6dac41-0cdc-4956-a37e-163e76c70733`;
            items[
              item
            ].imagesrc2 = `https://firebasestorage.googleapis.com/v0/b/stepbystep-b7ccc.appspot.com/o/shoes%2F${items[item].name}%2F${items[item].imagesrc2}?alt=media&token=ab6dac41-0cdc-4956-a37e-163e76c70733`;
            items[
              item
            ].imagesrc3 = `https://firebasestorage.googleapis.com/v0/b/stepbystep-b7ccc.appspot.com/o/shoes%2F${items[item].name}%2F${items[item].imagesrc3}?alt=media&token=ab6dac41-0cdc-4956-a37e-163e76c70733`;
            items[item].count = 1;
          }
          return items;
        });

      ctx.commit("updateShoes", rows);
    },
    addItemBasket(ctx, card) {
      ctx.commit("updateBasket", card);
    },
    deleteItemBasket(ctx, card) {
      ctx.commit("deleteItemBasket", card);
    },
  },
  modules: {},
});
