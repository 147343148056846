import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/shoes",
    name: "Catalog",
    component: function () {
      return import(
        /* webpackChunkName: "Catalog" */ "../views/CatalogView.vue"
      );
    },
  },
  {
    path: "/clothes",
    name: "Clothes",
    component: function () {
      return import(
        /* webpackChunkName: "Catalog" */ "../views/ClothesView.vue"
      );
    },
  },
  {
    path: "/register_clothes",
    name: "RegisterClothes",
    component: function () {
      return import(
        /* webpackChunkName: "Catalog" */ "../views/RegisterClothesView.vue"
      );
    },
  },
  {
    path: "/card/:id",
    name: "CartView",
    component: function () {
      return import(/* webpackChunkName: "Catalog" */ "../views/CartView.vue");
    },
  },
  {
    path: "/shoes/:id",
    name: "ShoesView",
    component: function () {
      return import(
        /* webpackChunkName: "Catalog" */ "../views/ShoesCartView.vue"
      );
    },
  },
  {
    path: "/register",
    name: "register",
    component: function () {
      return import(
        /* webpackChunkName: "register" */ "../views/RegisterView.vue"
      );
    },
  },
  {
    path: "/table",
    name: "table",
    component: function () {
      return import(/* webpackChunkName: "table" */ "../views/TableView.vue");
    },
  },
  {
    path: "/payments",
    name: "payments",
    component: function () {
      return import(/* webpackChunkName: "table" */ "../views/Payments.vue");
    },
  },
  {
    path: "/clothes_table",
    name: "clothes",
    component: function () {
      return import(
        /* webpackChunkName: "table" */ "../views/ClothesTableView.vue"
      );
    },
  },
  {
    path: "/cdek_integrator",
    name: "cdek_integrator",
    component: function () {
      return import(/* webpackChunkName: "table" */ "../views/CdekView.vue");
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
