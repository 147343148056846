<template>
  <nav
    class="
      relative
      w-full
      flex flex-wrap
      items-center
      justify-between
      py-4
      bg-[#F7F8FC]
      shadow-lg
			font-rubik
			max-w-[600px] flex justify-center mx-auto
    "
  >
    <div class="container-fluid flex flex-row justify-between w-full items-center">
      <a
        class="
          flex
          items-center
          text-gray-900
          hover:text-gray-900
          focus:text-gray-900
          mt-2
          lg:mt-0
          mr-1
          cursor-pointer
        "
        @click="this.$router.push('/shoes')"
      >
			<logo-nav/>
      </a>
      <router-link to="/shoes">Обувь</router-link>
      <router-link to="/clothes">Одежда</router-link>
    </div>
  </nav>
</template>
<script>
import LogoNav from './LogoNav.vue'
import FilterButtonSvg from './FilterButtonSvg.vue'
export default {
	components:{
		LogoNav,
		FilterButtonSvg
	}
}
</script>