<template>
	<div class="mb-16 max-w-[600px] flex flex-col justify-center mx-auto bg-[#fff] w-full">
		<div class="flex flex-row justify-center gap-1">
			<a href="https://www.ozon.ru/seller/stepbystep-97010/">
				<img src="../assets/ozon.png" class="rounded-3xl" alt="">
			</a>
			<a href="https://market.yandex.ru/business--step-by-step-116/43060717">
				<img src="../assets/yamarket.png" alt="">
			</a>
      <a href="https://sbermegamarket.ru/shops/stepbystep/">
        <img src="../assets/Sber.png" alt="">
      </a>
		</div>
		<div class="mt-7">
			<h1 class="font-rubik text-[#8697AF] text-base">(c) StepByStep 2022 г.</h1>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		
	}
}
</script>